@import '../partials';

.form-search {
  width: rem(64);
  height: rem(64);
  transition-property: width;
  transition-duration: $fast-duration;
  transition-timing-function: $cubic-ease;

  .header--search-focus &,
  .header--mobile-menu & {
    width: calc(100vw - 64px);

    @include bp(lg) {
      width: rem(300);
    }
  }

  &__boundary {
    padding-left: rem(64);
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  &__input {
    border: none;
    border-radius: 0;
    box-shadow: inset 0 -3px 0 0 rgba(225, 225, 225, 0.5);
    background-color: $white;
    padding: rem(17);
    font-family: $sans-serif-body-font;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  &__toggle {
    cursor: pointer;
    background-color: $green;
    padding: rem(18);
    position: absolute;
    left: 0;

    &-icon {
      display: block;
      width: rem(28);
      height: rem(28);
    }
  }
}